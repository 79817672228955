<svelte:options tag="fds-button"></svelte:options>

<script>
    import { onMount, beforeUpdate } from 'svelte'
    import Ripple from './ripple.svelte'
    import { writable } from 'svelte/store'
    import {themes } from "./themes.js"
    import {default as colors } from "./colors.js"
    import { get_current_component } from 'svelte/internal';
    import fdsHelper from "@fds-components/fds-helper"
    import { version as componentversion ,name } from '../package.json';

    /**
     * @typedef {string} cssColorString
     * @typedef {string} iconString
     */

    /**
     * Additional theme property overwrite current theme 
     * @type {number}
     */
    export let rippleblur=0
    /**
     * Additional theme property overwrite current theme 
     * @type {number}
     */
    export let speed=0;
    /**
     * Additional theme property overwrite current theme 
     * @type {cssColorString}
     */
    export let color="";
    /**
     * Additional theme property overwrite current theme 
     * @type {string}
     */
    export let fontsize="";
    /**
     * Additional theme property overwrite current theme 
     * @type {cssColorString}
     */
    export let bgcolor="";
    /**
     * Additional theme property overwrite current theme 
     * @type {string}
     */
    export let round="";
    /**
     * Additional theme property overwrite current theme 
     * @type {number}
     */
    export let iconheight=0;
    /**
    * The icon name if only icon has to be shown.
    * @type {iconString}
    */
    export let icon="";
    export let height=0;

    /**
     * The button width in CSS value (e.g. 100px)
     * @type {string}
     */
    export let width='auto';
    /**
     * Additional theme property overwrite current theme
     * @type {number}
     */
    export let sizeIn=0;
    /**
     * Additional theme property overwrite current theme
     * @type {number}
     */
    export let opacityIn=0;
    /**
     * Additional theme property overwrite current theme
     * @type {string}
     */
    export let shadow="";
    /**
     * Additional theme property overwrite current theme
     * @type {string}
     */
    export let shadowhover="";
    /**
     * Additional theme property overwrite current theme
     * @type {string}
     */
    export let border=""



    /**
     * The name of the theme

     * @type {"defaultTheme"|"materialLightBlue"|"materialLightBlueRounded"|"paper"|"paperCurl"|"squishy"}
     */
    export let theme=""
    /**
     * Button type see description below
     * @type {"primary"|"secondary"|"approve"|"decline"}
     */
    export let type=null;
    /**
     * Additional theme property overwrite current theme
      * @type {boolean}
     */
    export let circle=false;
    let path = fdsHelper.get_href();
    let shadowActive=""
    let bgHover="",bgActive=""
    let cinitialized = false;
    export let colorList
    /**
     * Set button to grey disabled state and deactivates onclick event.
     * @type {boolean}
     */
    export let disabled=false
    let host = get_current_component()

    colorList=colors
    let shadows = {
        none: 'none',
        1: '0 0 0 1px rgba(0, 0, 0, 0.05)',
        2: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
        3: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
        4: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
        5: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
        6: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
        7: '0 25px 50px -12px rgba(0, 0, 0, 0.25)'
    }, mouseX, mouseY

    /**
     * Get information about component
     * @param  {("api" | "examples" | "css")} type the info type
     */
    export async function getInfo(mtype) {
        if (mtype==="version"){
            return new Promise(resolve => {
                resolve(componentversion);
            });
        }
        let res = await fdsHelper.getInfo(mtype,name);
        return res;
    }

    /**
     * version of component
     * @type {string}
     */
    export const version = componentversion;


    function handleRipple() {
        const ripples = writable([]);

        return {
            subscribe: ripples.subscribe,

            add: item => {
                ripples.update( items => {
                    return [...items, item]
                });
            },
            clear: () => {
                ripples.update( items => {
                    return []
                });
            }
        }
    }

    const ripples = handleRipple();

    let rect, rippleBtn, w, h, x, y, offsetX, offsetY, deltaX, deltaY, locationY, locationX, scale_ratio, timer

    let coords = { x: 50, y: 50 }


    $: {
        if(disabled=='false') disabled=false;
        disabled = !!disabled;
        if(circle=='false') circle=false;
        circle = !!circle;
        if(cinitialized) setProps();
        if(type) setProps(true);
        }


    $: offsetX = Math.abs( (w / 2) - coords.x ),
        offsetY = Math.abs( (h / 2) - coords.y ),
        deltaX = (w / 2) + offsetX,
        deltaY = (h / 2) + offsetY,
        scale_ratio = Math.sqrt(Math.pow(deltaX, 2.2) + Math.pow(deltaY, 2.2))

    const debounce = () => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            ripples.clear()
        }, speed + (speed * 2));
    }

    let touch

    function handleClick(e, clicktype) {
        if (clicktype === 'touch') {
            touch = true
            ripples.add({ x: e.pageX - locationX, y: e.pageY - locationY, size: scale_ratio })
        } else {
            if (!touch) {
                ripples.add({ x: e.clientX - locationX, y: e.clientY - locationY, size: scale_ratio })
            }
            touch = false
        }
        debounce()
    }

    beforeUpdate(()=> {
       // setProps();
    })

    onMount(()=> {
        setProps();
        w = rippleBtn.offsetWidth
        h = rippleBtn.offsetHeight
        rect = rippleBtn.getBoundingClientRect()
        locationY = rect.y
        locationX = rect.x
    })
    const colorShade = (col, amt) => {
        col = col.replace(/^#/, '')
        if (col.length === 3) col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2]

        let [r, g, b] = col.match(/.{2}/g);
        ([r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt])

        r = Math.max(Math.min(255, r), 0).toString(16)
        g = Math.max(Math.min(255, g), 0).toString(16)
        b = Math.max(Math.min(255, b), 0).toString(16)

        const rr = (r.length < 2 ? '0' : '') + r
        const gg = (g.length < 2 ? '0' : '') + g
        const bb = (b.length < 2 ? '0' : '') + b

        return `#${rr}${gg}${bb}`
    }

    let error=""
    function convertColor(c) {
        if (!c) return c
        if (c.indexOf(".")<0) return c
        let arr=c.split(".")
        let main=arr[0]
        let sub=arr[1]
        sub=sub.replace(/-/,"")

        if (!colors[main]) {
            error="wrong color: "+c
            return "black"
        }
        if (!colors[main][sub]) {
            error="wrong color: "+c
            return "black"
        }
        return colors[main][sub]
    }

    export function getTheme(themetype,theme) {
        /* custom themes */
        if (window["fds-ui-config"] && window["fds-ui-config"].buttonThemes) {
            let customThemes = window["fds-ui-config"].buttonThemes
            for (let name in customThemes) {
                let obj = customThemes[name]
                let baseTheme = themes[obj.use]
                let newTheme = {...baseTheme}
                for (let key in obj) {
                    if (key !== "className") newTheme[key] = obj[key]
                }
                if (obj.use) themes[name]=newTheme
            }
        }

        if (!theme && window["fds-ui-config"]) theme=window["fds-ui-config"].buttonTheme
        if (!theme) theme="defaultTheme"
        let t= themes[theme]
        t=JSON.parse(JSON.stringify(t))

        if (!themetype) themetype="primary"
        t.speed=600
        t.sizeIn= 20
        t.rippleBlur=10
        t.shadowActive=t.shadowHover
        t.opacityIn=.2
        let arr=themetype.split("-")
        let colorType=arr[0]
        if (!t.back[colorType]) {
            error="Unknown color type: "+colorType
            t.height="30px"
            t.fontSize="10px"
        } else {
            t.bgColor=t.back[colorType]
        }

        let size=arr[1]
        if (!size) size="d"
        if (!t["size_"+size]) {
            error="Unknown size: "+size
            t.height="30px"
            t.fontSize="10px"
        } else {
            t.height=t["size_"+size].height
            t.fontSize=t["size_"+size].fontSize
            t.iconHeight=t["size_"+size].iconHeight
        }


        t.transparent = arr[2] === "t";
        t.outline = arr[2] === "o";
        t._bgColor=t.bgColor
        return t
    }
    let className="";

    function setProps(update) {
        className="btn default-btn";
        let prop=getTheme(type,theme)
        if(!update){
            if (rippleblur) prop.rippleBlur=rippleblur
            if (speed) prop.speed=speed
            if (color) prop.color=color
            if (fontsize) prop.fontSize=fontsize
            if (bgcolor) prop.bgColor=bgcolor
            if (height) prop.height=height
            if (round) prop.round=round
            if (sizeIn) prop.sizeIn=sizeIn
            if (opacityIn) prop.opacityIn=opacityIn
            if (shadow) prop.shadow=shadow
            if (shadowhover) prop.shadowHover=shadowhover
            if (shadowActive) prop.shadowActive=shadowActive
            if (iconheight) prop.iconHeight=iconheight
        }
        if (width) prop.width=width

        rippleblur=prop.rippleBlur
        speed=prop.speed
        color=prop.color
        fontsize=prop.fontSize
        bgcolor=prop.bgColor
        color=convertColor(color)

        bgcolor=convertColor(bgcolor)
        prop.colorTransparent=convertColor(prop.colorTransparent)
        bgHover=colorShade(bgcolor,10)
        bgActive=colorShade(bgcolor,10)
        round=prop.round
        height=prop.height
        width=prop.width
        sizeIn=prop.sizeIn
        opacityIn=prop.opacityIn
        shadow=prop.shadow
        shadowhover=prop.shadowHover
   //     shadowActive=prop.shadowActive
        iconheight=prop.iconHeight

        if (!width){
            width="auto"
        }
        if (icon) circle=true;
        if (circle===true) {
            round="100%"
            width=height
        }

        if (prop.transparent) {
            if (prop.colorTransparent) color=prop.colorTransparent
            if (!prop.noTransparent) bgcolor="transparent"
        }
        if (prop.outline) {
            if (!prop.noTransparent) {
                border="1px solid "+prop._bgColor
                bgcolor="transparent"
                if (prop.colorTransparent) color=prop.colorTransparent
            } else {
                border="1px solid "+prop.colorTransparent
            }

        } else {
            border="none"
        }
        if (prop.className) className=prop.className

        if (shadows[shadow]) shadow=shadows[shadow]
        if (shadows[shadowhover]) shadowhover=shadows[shadowhover]
   //     if (shadows[shadowActive]) shadowActive=shadows[shadowActive]
        shadowActive=shadowhover
        if ( disabled==true) {        // bring it to host
            host.setAttribute("onclick",() => {})
            className+=" disabled"
        }
        if (error) console.log(error);
        cinitialized = true;
    }
</script>

<button  on:mousedown={e => handleClick(e, 'click')}
         on:click class="{className}"
         style="--color: {color};--border:{border};--font-size: {fontsize};--bg-color: {bgcolor};--bg-hover: {bgHover};--bg-active: {bgActive};--radius: {round};--height: {height};--width: {width};--shadow: {shadow};--shadow-h: {shadowhover};--shadow-a: {shadowActive}" bind:this={rippleBtn} on:touchstart={e => handleClick(e.touches[0], 'touch')}  >
	<span>
        {#if error}[{error}]{/if}
        {#if icon && circle===true}
            <fds-icon name="{icon}" height="{iconheight}" style="--color:{color}"></fds-icon>
        {/if}
		<slot></slot>
	</span>
    <svg>
        {#each $ripples as ripple, index}
            <Ripple x="{ripple.x}" y="{ripple.y}" size={ripple.size} {speed} {sizeIn} {opacityIn} {rippleblur}/>
        {/each}

    </svg>
</button>

<style>
    .btn {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: none;
        font-weight: 500;
        color: var(--color);
        font-size: var(--font-size);
        height: var(--height);
        width: var(--width);
        max-width: 100%;
        margin: 0;
        padding: 10px 16px;
        position: relative;
        border-radius: var(--radius);
        cursor: pointer;
     /*   -webkit-transition: 200ms all ease-out;
        transition: 200ms all ease-out;*/
        background-color: var(--bg-color);
        overflow: hidden;
        box-shadow: var(--shadow);
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-tap-highlight-color: transparent;
        border: var(--border);
    }
    .disabled {
        filter: grayscale(1);
        cursor: not-allowed !important;
    }
    .disabled:before {
        height:0 !important;
    }
    .disabled:after {
        height:0 !important;
    }
    .default-btn:hover,
    .default-btn:focus {
        outline: none;
        background-color: var(--bg-hover);
        box-shadow: var(--shadow-h);
        transition: 250ms all ease-out;
    }
    .default-btn:active {
        outline: none;
        background-color: var(--bg-active);
        box-shadow: var(--shadow-a)
    }
    span {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 0;
        z-index: 1;
    }
    svg {
        height: 100%;
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
    }

    /* Base Button Style */
    .paper-btn {
        cursor: pointer;
        position: relative;
        display: inline-block;
        text-align: center;
        text-decoration: none;
        transform-style: flat;
        color: var(--color);
        font-size: var(--font-size);
        height: var(--height);
        width: var(--width);
        border: var(--border);
        background-color: var(--bg-color);
        border-radius: var(--radius);

    }
    .paper-btn:hover {
        transition: all 250ms ease-out;

    }
    .paper-btn:before, .paper-btn:after {
        content: "";
        position: absolute;
        z-index: -2;
        transition: all 250ms ease-out;
    }



    /* Lift effect – lifts sides on hover */
    .paper-lift:before, .paper-lift:after {
        bottom: 15px;
        width: 50%;
        height: 20%;
        max-width: 300px;
        max-height: 100px;
        box-shadow: 0 10px 10px rgba(31, 31, 31, 0.5);
    }
    .paper-lift:before {
        left: 8px;
        transform: rotate(-3deg);
    }
    .paper-lift:after {
        right: 8px;
        transform: rotate(3deg);
    }
    .paper-lift:hover {
        border-color: transparent;
    }
    .paper-lift:hover:before, .paper-lift:hover:after {
        box-shadow: 0 15px 12px rgba(31, 31, 31, 0.7);
    }
    .paper-lift:hover:before {
        left: 10px;
    }
    .paper-lift:hover:after {
        right: 10px;
    }


    /* Curl effects – curls corners on hover */
    .paper-curl:before, .paper-curl:after,
    .paper-curl-right:before,
    .paper-curl-right:after {
        bottom: 12px;
        width: 50%;
        height: 55%;
        max-width: 200px;
        max-height: 100px;
        box-shadow: 1px 8px 12px rgba(31, 31, 31, 0.6);
    }
    .paper-curl:before,
    .paper-curl-right:before {
        left: 10px;
    }
    .paper-curl:after,
    .paper-curl-right:after {
        right: 10px;
    }
    .paper-curl:hover:before, .paper-curl:hover:after,
    .paper-curl-right:hover:before,
    .paper-curl-right:hover:after {
        box-shadow: 1px 8px 12px rgba(31, 31, 31, 0.8);
    }
    .paper-curl:hover:before,
    .paper-curl-right:hover:before {
        transform: skew(-8deg) rotate(-3deg);
    }
    .paper-curl:hover:after,
    .paper-curl-right:hover:after {
        transform: skew(8deg) rotate(3deg);
    }

    .paper-curl:hover {
        border-radius: 0 0 40% 40%/0 0 30% 30%;
    }

    .paper-curl-right:before {
        display: none;
    }
    .paper-curl-right:hover {
        border-radius: 0 0 40% 0/0 0 30% 0;
    }
    .squishy {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: none;
        font-weight: 500;
        color: var(--color);
        font-size: var(--font-size);
        height: var(--height);
        width: var(--width);
        max-width: 100%;
        margin: 0;
        padding: 0;
        position: relative;
        border-radius: var(--radius);
        background-color: var(--bg-color);
        overflow: hidden;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-tap-highlight-color: transparent;
/*        background: rgb(228, 232, 236);*/
        cursor:pointer;
        box-shadow: -2px -7px 10px #f1f1f1, 4px 6px 10px #d4d7da;
    }
    .squishy:active, .squishy:hover {
      /*  background:  rgb(218, 221, 224);*/
/*        background: linear-gradient(45deg, rgb(218, 221, 224) 0%, rgb(241, 241, 241) 100%); */
        box-shadow:
                -1px -3px 5px #d4d7da,
                2px 3px 5px #f1f1f1,
                inset -1px -3px 5px #f1f1f1,
                inset 2px 3px 5px #d4d7da;
        transition: all .3s;

    }

</style>