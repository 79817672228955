import {default as colors} from "./colors.js"


const materialLightBlue = {
    back: {
        primary: colors.lightBlue.lighten1,
        secondary: colors.blueGrey.lighten3,
        approve: colors.green.lighten1,
        decline: colors.red.lighten1
    },
    color: colors.shades.white,
    colorTransparent: colors.shades.black,
    round: 0,
    shadow: "",
    shadowHover: 5,
    size_xxs: {
        height: "16px",
        fontSize: "14px",
        iconHeight: 9
    },
    size_xs: {
        height: "26px",
        fontSize: "14px",
        iconHeight: 15
    },
    size_d: {
        height: "36px",
        fontSize: "14px",
        iconHeight: 15
    },
    size_xl: {
        height: "46px",
        fontSize: "14px",
        iconHeight: 20
    },
    size_xxl: {
        height: "56px",
        fontSize: "14px",
        iconHeight: 30
    }
}
const materialLightBlueRounded = {...materialLightBlue}
materialLightBlueRounded.round = "7px"
const paper = {...materialLightBlue}
paper.noTransparent = true
paper.color = colors.grey.darken1
paper.back = {
    primary: colors.shades.white,
    secondary: colors.grey.lighten5,
    approve: colors.green.lighten4,
    decline: colors.red.lighten4
}
paper.colorTransparent = colors.grey.lighten1
paper.className = "paper-btn paper-lift"
const paperCurl = {...paper}
paperCurl.className = "paper-btn paper-curl-right"

const squishy = {...paper}
squishy.className = "squishy"
squishy.round = "10px"
squishy.back = {
    primary: "#e4e8ec",
    secondary: "#e4e8ec",
    approve: colors.green.lighten4,
    decline: colors.red.lighten4

}
squishy.rippleBlur = 10
export const themes = {
    defaultTheme: materialLightBlue,
    materialLightBlue: materialLightBlue,
    materialLightBlueRounded: materialLightBlueRounded,
    paper: paper,
    paperCurl: paperCurl,
    squishy: squishy
}
